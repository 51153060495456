<template>
  <div class="pillar-container">
    <swiper ref="swiper" class="swiper-container" :options="options">
      <swiper-slide class="swiper-slide" v-for="(item,index) in pillarItemsChanged" :key="index">
        <PillarItem :pillar-item="item"/>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
  import PillarItem from "./PillarItem"
  import log from "@/util/log/"
  import device from "@/api/device"

  export default {
    name      : "PillarContainer",
    components: {PillarItem},
    props     : {
      pillarItems: {
        type   : Array,
        default: []
      },
      isLoad     : {
        type   : Boolean,
        default: false
      },

      noMoreData: {
        type   : Boolean,
        default: false
      },

      isFirstLoad: true,
    },
    data() {
      return {
        options : {
          slidesPerView      : 6,
          centeredSlides     : true,
          slideToClickedSlide: true,
          on                 : {
            slideChange: this.slideChange,
            toEdge     : this.toEdge,
            sliderMove : this.sliderMove
          }
        },
        lastSize: 0
      }
    },

    methods: {
      slideChange() {
        const index = this.$refs.swiper.$swiper.activeIndex
        this.$emit("onSlideChanged", this.pillarItemsChanged[index], index)
      },

      toEdge() {
        if (this.$refs.swiper.$swiper) {
        }
      },
      sliderMove(swiper, event) {
        const translate   = swiper.translate
        const perViewSize = this.options.slidesPerView
        const width       = swiper.width
        const perWidth    = width / perViewSize
        if (translate >= -perWidth && !this.isLoadStatus) {
          log("加载下一页")
          this.$emit("onLoadMore", this.pillarItemsChanged[0], this.pillarItemsChanged.length - 1)
        }
      }
    },
    watch  : {
      pillarItemsChanged(oldValue, newValue) {
        if (this.$refs.swiper.$swiper) {
          if (this.isFirstLoad) {
            this.lastSize = newValue.length
            this.$refs.swiper.$swiper.slideTo(newValue.length - 1, 2000, false)
          } else {
            const index = newValue.length - this.lastSize
            this.$refs.swiper.$swiper.slideTo(index)
            this.lastSize = newValue.length
          }
        }
      }
    },

    computed: {
      pillarItemsChanged() {
        return this.pillarItems
      },
      isLoadStatus() {
        return this.isLoad
      }
    }
  }
</script>

<style lang="less" scoped>
  .pillar-container {
    display: flex;
    flex-direction: column;
    height: 180px;

    .swiper-container {
      width: 100%;

      .swiper-slide {
        text-align: center;
        font-size: 18px;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }
    }
  }

</style>