<template>
  <div class="pillar-detail-container">
    <span class="time">{{pillarItem.createTime}}</span>
    <div class="pillar-item-container">
      <div class="pillar-item  mt-8" :style="`height: ${percent}%`"></div>
    </div>
  </div>
</template>



<script>
  export default {
    name : "PillarItem",
    props: {
      pillarItem: {
        type   : Object,
        default: () => {
        }
      }
    },
    data() {
      return {}
    },
    computed: {
      percent() {
        return this.pillarItem.totalDuration / this.pillarItem.maxValue * 100
      }
    }
  }
</script>

<style lang="less" scoped>
  .pillar-detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    height: 180px;

    .time {
      font-size: 10px;
      color: #F6F6F6;
    }

    .pillar-item-container {
      display: flex;
      flex: 1;
      flex-direction: column-reverse;

      .pillar-item {
        width: 20px;
        background-color: #ed6a0c;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

  }
</style>