<template>

    <div class="my-record-container">

      <van-tabs v-model="active" color="#F6F6F6" background="#222222" title-active-color="#F6F6F6">
        <van-tab title="Daily">
          <MyRecordDetail :date-type="1" :oil-type="oilType"/>
        </van-tab>

        <van-tab title="Weekly">
          <MyRecordDetail :date-type="2" :oil-type="oilType"/>
        </van-tab>
        <van-tab title="Month">
          <MyRecordDetail :date-type="3" :oil-type="oilType"/>
        </van-tab>
      </van-tabs>
    </div>

</template>

<script>

  import PillarContainer from "./PillarContainer"
  import MyRecordDetail from "./MyRecordDetail"
  import device from "@/api/device"

  const echarts = require('echarts');
  export default {
    name      : "MyRecord",
    components: {MyRecordDetail, PillarContainer},
    data() {
      return {
        active      : "1",
        oilType     : 0,
        smokeOilType: [],
        showPopover : false,
        actions     : [],
        oilTypeName : 'All'
      }
    },
    methods   : {
      backClick() {
        this.$router.back()
      },

      onSelect(action) {
        const id         = action.item.id
        this.oilType     = id
        this.oilTypeName = action.item.typeName
      },
      onOilTypeClick() {
        this.showPopover = true
      }
    },
    mounted() {
      device.smokeOilType()
          .then(data => {
            data.forEach(item => {
              this.smokeOilType.push({text: item.typeName, item})
            })
          })
    },
    computed  : {}
  }
</script>

<style lang="less" scoped>
  .my-record-container {
    display: flex;
    flex-direction: column;
  }

  .title {
    flex: 4;
    font: 18px bold;
    margin-right: 11px;
    text-align: center;

    ::after {
      width: 13px;
      height: 7px;
      margin-left: 8px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-image: require("../../assets/ui/record/bottom_expand.png");
    }
  }


</style>