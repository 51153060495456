<template>
  <div class="my-record-container">
    <van-pull-refresh
      v-model="isRefresh"
      success-text="Refresh Successfully"
      loading-text="Loading"
      pulling-text="Pull To Refresh"
      loosing-text="Release to refresh"
      @refresh="onRefresh"
    >
      <div class="mt-4 pillar-container">
        <PillarContainer
          :pillar-items="pillarItems"
          v-on:onSlideChanged="onSlideChanged"
          v-on:onLoadMore="onLoadMore"
          :is-load="isLoad"
          :is-first-load="isFirstLoad"
        />
        <div class="chart-line-legend-data">
          <div ref="lineChart" class="line-chart-data"></div>
        </div>
        <div class="duration-info-container mt-4">
          <span>{{ chartLineData.newSmokingTimeRecordVo.createTime }}</span>
          <div class="duration-info mt-4">
            <div class="place-holder"></div>
            <div class="duration-info">
              <span class="title">{{
                chartLineData.newSmokingTimeRecordVo.totalDuration
              }}</span>
              <span>Duration(s)</span>
            </div>
            <div class="place-holder"></div>
            <div class="duration-info">
              <span class="title">{{
                chartLineData.newSmokingTimeRecordVo.dose
              }}</span>
              <span>Dosage(mg)</span>
            </div>
            <div class="place-holder"></div>
          </div>
        </div>

        <div style="height: 1vh"></div>

        <div v-for="(item, i) in smokeItems" class="mr-2">
          <div v-for="(target, index) in item.brandAbsorbedDose">
            <div class="duration-item-container">
              <span v-if="index === 0" class="duration-time">{{
                convertSection(item.section)
              }}</span>
              <span v-else class="duration-time"></span>

              <div class="time-line-container">
                <span class="circle"></span>
                <span class="line"></span>
              </div>

              <div class="duration-time-info mt-4">
                <span>{{ target.flavor }}</span>
                <div class="detail mt-4">
                  <span class="record-dosage-img"></span>
                  <span class="item ml-1">{{ target.dosage }}</span>
                  <span class="record-time-img"></span>
                  <span class="item ml-1">{{ target.frequency }}s</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>


<script>
import PillarContainer from "./PillarContainer";
import log from "@/util/log/";
import MyRecordDetail from "./MyRecordDetail";
import device from "@/api/device";
import { Toast } from "vant";

const echarts = require("echarts");
export default {
  name: "MyRecordDetail",
  components: { MyRecordDetail, PillarContainer },
  props: {
    dateType: {
      type: Number,
      default: 0,
    },
    oilType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      lineChart: null,
      options: {
        legend: {
          textStyle: {
            color: "#F6F6F6",
          },
          data: [],
        },
        series: {
          type: "line",
          label: {
            normal: {
              show: true,
              position: "outside",

              textStyle: {
                align: "center",
                baseline: "middle",
                fontFamily: "微软雅黑",
                fontSize: 8,
                fontWeight: "bolder",
              },
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
          axisLabel: {
            color: "#F6F6F6",
            fontSize: 10,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#F6F6F6",
            fontSize: 10,
          },
        },
        color: ["#ED6C0F", "#999999", "#76BC84"],
      },
      pillarItems: [],
      chartLineData: {
        map: { current: null, prev: null, avg: null },
        newSmokingTimeRecordVo: {},
      },
      pageNum: 1,
      isLoad: false,
      isRefresh: false,
      isFirstLoad: true,
      enableLoadMore: true,
      currentPillarItem: null,
    };
  },
  methods: {
    onRefresh() {
      device
        .chartLineSmokingTimeRecord(
          this.dateType,
          this.oilType,
          this.currentPillarItem.createTime,
          null
        )
        .then((data) => {
          this.chartLineData = data;
          this.handleChartLineData(data);
          this.isRefresh = false;
        });
    },
    handleChartLineData(chartLineData) {
      const data = [];
      const legendData = [];
      const xAxisData = [];
      const { current, prev, avg } = chartLineData.map;
      if (current) {
        data.push(this.handleCategoryData("My Record", current));
        legendData.push("My Record");

        current.forEach((item) => {
          xAxisData.push(item.createTime);
        });
      }

      if (prev) {
        let prevLegend = "";
        switch (this.dateType) {
          case 1:
            prevLegend = "Yesterday";
            break;
          case 2:
            prevLegend = "Last Week";
            break;
          case 3:
            prevLegend = "Last Month";
            break;
        }
        data.push(this.handleCategoryData(prevLegend, prev));
        legendData.push(prevLegend);
      }

      if (avg) {
        data.push(this.handleCategoryData("Avg", avg));
        legendData.push("Avg");
      }

      this.options.series = data;
      this.options.legend = { ...this.options.legend, data: legendData };
      this.options.xAxis = { ...this.options.xAxis, data: xAxisData };
      this.renderLineChartData();
    },
    handleCategoryData(name, data) {
      const resultData = [];
      data.forEach((item) => {
        resultData.push(item.dose);
      });
      return { name, type: "line", smooth: true, data: resultData };
    },
    renderLineChartData() {
      //渲染折线图
      if (!this.lineChart) {
        return;
      }
      this.lineChart.setOption(this.options);
    },
    convertSection(section) {
      //转换时间
      if (this.dateType === 1) {
        const splitSection = section.split("-");
        const sectionData = [];
        splitSection.forEach((item) => {
          if (item.length < 2) {
            sectionData.push(`0${item}:00`);
          } else {
            sectionData.push(`${item}:00`);
          }
        });
        return sectionData.join("-");
      } else {
        return section;
      }
    },
    onSlideChanged(pillarItem, index) {
      log(`slide index = ${index} item=${pillarItem}`);
      this.currentPillarItem = pillarItem;
      device
        .chartLineSmokingTimeRecord(
          this.dateType,
          this.oilType,
          pillarItem.createTime,
          null
        )
        .then((data) => {
          this.chartLineData = data;
          this.handleChartLineData(data);
        });
    },
    onLoadMore(pillarItem, index) {
      //加载更多
      if (!this.enableLoadMore) {
        return;
      }
      if (this.isLoad) {
        return;
      }

      this.isFirstLoad = false;

      // Toast.loading("loading...");
      this.showLoading("Loading...");

      const pageNum = this.pageNum + 1;
      this.isLoad = true;
      device
        .selectChartLineHistogram(
          this.dateType,
          this.oilType,
          pillarItem.createTime,
          pageNum
        )
        .then((data) => {
          data.forEach((item) => {
            this.pillarItems.unshift(item);
          });

          const result = this.pillarItems.reduce((prev, current) => {
            if (prev.totalDuration > current.totalDuration) {
              return prev;
            } else {
              return current;
            }
          }).totalDuration;

          this.pillarItems.forEach((item) => {
            item.maxValue = result;
          });
          ++this.pageNum;
          this.isLoad = false;
          this.enableLoadMore = data.length >= 10;

          clearToast();
        })
        .finally((res) => {
          this.hideLoading();
        });
    },
    loadData() {
      device
        .selectChartLineHistogram(
          this.dateType,
          this.oilType,
          null,
          this.pageNum
        )
        .then((data) => {
          const reverseData = data.reverse();
          reverseData.forEach((item) => {
            this.pillarItems.push(item);
          });

          const result = this.pillarItems.reduce((prev, current) => {
            if (prev.totalDuration > current.totalDuration) {
              return prev;
            } else {
              return current;
            }
          }).totalDuration;

          this.pillarItems.forEach((item) => {
            item.maxValue = result;
          });

          this.currentPillarItem =
            this.pillarItems[this.pillarItems.length - 1];
          return device.chartLineSmokingTimeRecord(
            this.dateType,
            this.oilType,
            this.currentPillarItem.createTime,
            null
          );
        })
        .then((data) => {
          this.chartLineData = data;
          this.handleChartLineData(data);
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.lineChart = echarts.init(this.$refs.lineChart);
      this.loadData();
    });
  },

  watch: {
    oilType(oldValue, newValue) {
      if (newValue !== oldValue) {
        this.isFirstLoad = true;
        this.pageNum = 1;
        this.pillarItems = [];
        this.loadData();
      }
    },
  },
  computed: {
    smokeItems() {
      const newDayRecordingVo =
        this.chartLineData.newSmokingTimeRecordVo.newDayRecordingVo;
      if (!newDayRecordingVo) return [];
      const items = newDayRecordingVo.filter((item) => item.brandAbsorbedDose);
      return items;
    },
  },
};
</script>

<style lang="less" scoped>
.pillar-container {
  display: flex;
  flex-direction: column;

  .chart-line-legend-data {
    display: flex;
    margin-top: 18px;
    flex-direction: column;
    height: 200px;

    .line-chart-data {
      flex: 1;
    }
  }
}

.duration-info-container {
  color: #999999;
  padding: 16px;
  font-size: 12px;
  border-top: #999999 1px solid;
  border-bottom: #999999 1px solid;
  display: flex;
  flex-direction: column;
}

.duration-info {
  display: flex;
  flex-direction: row;

  .place-holder {
    flex: 1;
  }

  .duration-info {
    text-align: center;
    display: flex;
    flex-direction: column;

    .title {
      font-weight: bold;
      color: #f6f6f6;
      font-size: 24px;
    }
  }
}

.duration-item-container {
  display: flex;
  flex-direction: row;
  color: #f6f6f6;
  text-align: center;

  .duration-time {
    min-width: 25vw;
    margin-left: 2vw;
    margin-right: 2vw;
  }

  .time-line-container {
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    .circle {
      background: #f6f6f6;
      width: 8px;
      height: 8px;
      min-height: 8px;
      border-radius: 50%;
    }

    .line {
      background: #f6f6f6;
      width: 2px;
      flex: 1;
      margin-left: 3px;
      min-height: 100%;
    }
  }

  .duration-time-info {
    text-align: start;
    padding: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: #292929;

    .detail {
      display: flex;
      flex-direction: row;
      align-items: center;

      .item {
        flex: 1;
      }
    }
  }
}

.record-time-img {
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url("../../assets/ui/record/record_time.png");
}

.record-dosage-img {
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url("../../assets/ui/record/record_dosage.png");
}
</style>